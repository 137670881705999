import { COLORS } from '@global/styles/colors';
import { DESKTOP_1280, MOBILE_575, TABLET_768 } from '@global/styles/sizes';
import styled from 'styled-components';

export const TrainingContainer = styled.a`
  width: min-content;
  box-sizing: border-box;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    .training-data {
      background-color: #f2f7fb;
    }
  }

  @media (max-width: ${DESKTOP_1280}px) {
    width: 100%;
  }
`;

export const ImageContainer = styled.div`
  width: 275px;
  height: 140px;
  position: relative;
  border-radius: 8px 8px 0 0;

  img {
    height: 140px !important;
    border-radius: 8px 8px 0 0;
    object-fit: cover;
    object-position: left;
  }
  @media (max-width: ${DESKTOP_1280}px) {
    width: 100%;
  }
`;

export const Tag = styled.div`
  width: 76px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background-color: ${COLORS.violetRed};
  border-radius: 0 8px 0 8px;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: ${COLORS.white};
`;

export const DataContainer = styled.div`
  width: 241px;
  min-height: 211px;
  padding: 15px 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 6px;
  border-radius: 0 0 8px 8px;
  border: 1px solid #d5d5d5;
  border-top: none;

  @media (max-width: ${DESKTOP_1280}px) {
    width: 100%;
    min-height: 241px;
    box-sizing: border-box;
  }
`;

export const TitleContainer = styled.div`
  max-height: 78px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Poppins';
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
  color: ${COLORS.black};
`;

export const SubtitleContainer = styled.div`
  min-height: 60px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Poppins';
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.resolutionBlue};
`;

export const PublicationDate = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-family: 'Poppins';
  color: #161616;
  margin-bottom: 10px;
`;

export const Separator = styled.div`
  width: 100%;
  height: 2px;
  background-color: #d5d5d5;
  margin-bottom: 5px;
`;

export const TrainingDate = styled.div`
  margin-left: 3px;
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  font-family: 'Poppins';
  color: ${COLORS.resolutionBlue};
`;

export const SeparateLine = styled.div`
  margin: 0 5px;
  font-size: 16px;
  line-height: 16px;
  color: ${COLORS.violetRed};
`;
export const TrainingSchedule = styled.div`
  @media (max-width: ${TABLET_768}px) {
    font-size: 18px;
  }
  @media (max-width: ${MOBILE_575}px) {
    font-size: 20px;
  }
  padding-bottom: 5px;
  display: flex;
  align-items: flex-end;
  color: ${COLORS.resolutionBlue};
  font-size: 20px;
  line-height: 21px;
  font-family: 'Poppins';
  font-weight: Bold;
  border-bottom: 1px solid #d5d5d5;
`;

export const TrainingTopic = styled.p`
  width: max-content;
  min-height: 20px;
  margin-top: 6px;
  font-family: 'Poppins';
  font-size: 12px;
  line-height: 16px;
  color: #0e8cb9;
  text-decoration: none;
`;

export const TopicContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
