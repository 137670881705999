import React from 'react';
import { graphql } from 'gatsby';

import Head from '@components/Head';

import { LayoutContainer } from '@components/Layout';
import { WelcomePromoBar } from '@global/data/promobarsData';
import BlogList from '@sections/BlogList/BlogList';
import BlogIndexHero from '@sections/BlogIndexHero/BlogIndexHero';
import QuickAccessPanelIndex from '@sections/QuickAccessPanelIndex/QuickAccessPanelIndex';
import { GlobalWrapper } from '@global/styles/grid';
import { CollectionIndexContainer, InActionContainer, QuickAccessContainer } from './styles';

const BlogIndex = ({ data }: any) => {
  const landingLayoutData = data?.cms?.landing?.data?.attributes?.Layout;
  const blogs = data?.cms?.blogs?.data;
  const topicsFilter = data?.cms?.blogTopics?.data;
  const rolesFilter = data?.cms?.blogRoles?.data;
  const typesFilter = data?.cms?.typeFilters?.data;
  const pathfulModuleFilter = data?.cms?.blogCareerClusters?.data;
  const quickAccessPanelData = data?.cms?.inActionQuickPanelAccesses?.data;

  const handleSortedBlogs = () => {
    const sortedBlogsViaPublicationDate = blogs.sort((blog1, blog2) => {
      return new Date(blog1.attributes.Publication_Date) <
        new Date(blog2.attributes.Publication_Date)
        ? 1
        : -1;
    });

    return sortedBlogsViaPublicationDate;
  };

  return (
    <main>
      <Head
        title="Your College & Career Readiness Journey | Pathful"
        description="Pathful is the complete college and career readiness system that helps students decide what they want to be and how to prepare for life beyond the classroom."
        tag="classroom, career, internship, learning, curriculum, career exploration, discovery, planning, path, work-based learning, career readiness, education outreach, real-world learning, course planner, graduation plans, life skills, digital lessons, virtual lessons, virtual job shadow, job shadow, job shadowing, career videos, career technical education, career placement, K12 internship opportunities"
        url="https://pathful.com/"
        imageUrl="https://pathful.com/pathful_preview.png"
      />
      <LayoutContainer
        promobarConfig={WelcomePromoBar}
        activeLogo={landingLayoutData?.Topbar_Scroll_Logo}
        topbarBorderColor={landingLayoutData?.Topbar_Details_Color}
        topbarMenuId={landingLayoutData?.Topbar_Menu}
        footerMenuId={landingLayoutData?.Footer_Menu}
        floatingTopbar
      >
        <CollectionIndexContainer>
          <BlogIndexHero />
          <GlobalWrapper>
            <InActionContainer>
              <QuickAccessContainer isMainAccess>
                <QuickAccessPanelIndex data={quickAccessPanelData} />
              </QuickAccessContainer>
              <BlogList
                blogs={handleSortedBlogs()}
                topicsFilter={topicsFilter}
                pathfulModuleFilter={pathfulModuleFilter}
                rolesFilter={rolesFilter}
                typesFilter={typesFilter}
              />
            </InActionContainer>
          </GlobalWrapper>
        </CollectionIndexContainer>
      </LayoutContainer>
    </main>
  );
};

export const pageQuery = graphql`
  query ($id: ID!) {
    cms {
      inActionQuickPanelAccesses(pagination: { limit: -1 }) {
        data {
          attributes {
            Quick_Panel_Access_CTA_Link
            Quick_Panel_Access_CTA_Text
            Quick_Panel_Access_Subtitle
            Quick_Panel_Access_Title
            Quick_Panel_Access_Icon {
              data {
                attributes {
                  alternativeText
                  url
                  mime
                  urlJSON
                }
              }
            }
          }
        }
      }
    }
    cms {
      landing(id: $id) {
        data {
          attributes {
            Layout {
              Topbar_Details_Color
              Topbar_Scroll_Logo {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
              Topbar_Menu
              Footer_Menu
            }
          }
        }
      }
      blogs(pagination: { limit: -1 }, filters: { Hide_Blog: { eq: false } }) {
        data {
          attributes {
            slug
            Title
            Subtitle
            Publication_Date
            Small_Image {
              data {
                attributes {
                  alternativeText
                  url
                  mime
                  urlJSON
                }
              }
            }
            Primary_Field
            Roles {
              data {
                attributes {
                  Role
                }
              }
            }
            Topics {
              data {
                attributes {
                  Topic
                }
              }
            }
            Pathful_Modules {
              data {
                attributes {
                  Pathful_Module
                }
              }
            }
            Type_Filters {
              data {
                attributes {
                  Type
                }
              }
            }
          }
        }
      }
      blogCareerClusters {
        data {
          attributes {
            Pathful_Module
          }
        }
      }
      blogRoles {
        data {
          attributes {
            Role
          }
        }
      }
      blogTopics {
        data {
          attributes {
            Topic
          }
        }
      }
      typeFilters {
        data {
          attributes {
            Type
          }
        }
      }
    }
  }
`;

export default BlogIndex;
