import React from 'react';
import { CMSParseImage } from '@components/StrapiComponents/ImageParser';
import { transformToSlug } from 'helpers/slugHelper';
import { PATHS } from '@global/constants/urls';

import {
  MaterialContainer,
  ImageContainer,
  DataContainer,
  Tag,
  TitleContainer,
  SubtitleContainer,
  MaterialTopic,
  PrimaryRole,
} from './materialCard.styles';
import { MaterialCardProps } from './materialCard.types';

const MaterialCard = ({ material }: MaterialCardProps) => {
  return (
    <MaterialContainer
      href={`${PATHS.MATERIAL}/${transformToSlug(material.attributes.slug.toLowerCase())}`}
    >
      <ImageContainer>
        <Tag colorTag={material.attributes.Tag?.data?.attributes?.Text_Color}>
          {material.attributes.Tag?.data?.attributes?.Type || 'Resources'}
        </Tag>
        {CMSParseImage(material.attributes.Small_Image.data)}
      </ImageContainer>
      <DataContainer className="material-data">
        <PrimaryRole>{material.attributes.Primary_Role.data?.attributes?.Role}</PrimaryRole>
        <TitleContainer>{material.attributes.Title}</TitleContainer>
        <SubtitleContainer>{material.attributes.Subtitle}</SubtitleContainer>
        <MaterialTopic
          href={`${PATHS.RESOURCE_CENTER}/?filter=${material.attributes?.Primary_Field || ''}`}
        >
          {material.attributes?.Primary_Field}
        </MaterialTopic>
      </DataContainer>
    </MaterialContainer>
  );
};

export default MaterialCard;
