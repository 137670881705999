import styled from 'styled-components';

export const VideoSection = styled.div`
  //display: flex;
  width: 90vw;
  height: 90vh;
  max-width: 1366px;
  max-height: 840px;
  margin: 0 auto;
  padding: 0 30px;
  position: relative;
`;

export const VideoContainer = styled.div`
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
