import React from 'react';
import { GlobalWrapper } from '@global/styles/grid';
import { CMSParseImage } from '@components/StrapiComponents/ImageParser';
import { QuickAccessPanelProps } from './quickAccessPanel.types';
import {
  BoxContainer,
  Button,
  DataWrapper,
  ImageContainer,
  SubtitleContainer,
  TextContainer,
  TitleContainer,
} from './quickAccessPanel.styles';

const QuickAccessPanel = ({ data, isResourceCenter }: QuickAccessPanelProps) => {
  return (
    <GlobalWrapper>
      <DataWrapper isResourceCenter={isResourceCenter}>
        {data.map((quickAccessPanel) => (
          <BoxContainer
            isMainAccess={!!quickAccessPanel.attributes.Quick_Panel_Access_Subtitle}
            isResourceCenter={isResourceCenter}
          >
            <TextContainer>
              <TitleContainer>
                {quickAccessPanel.attributes.Quick_Panel_Access_Title}
              </TitleContainer>
              {quickAccessPanel.attributes.Quick_Panel_Access_Icon?.data && (
                <ImageContainer>
                  {CMSParseImage(quickAccessPanel.attributes.Quick_Panel_Access_Icon)}
                </ImageContainer>
              )}
            </TextContainer>
            {quickAccessPanel.attributes.Quick_Panel_Access_Subtitle && (
              <SubtitleContainer>
                {quickAccessPanel.attributes.Quick_Panel_Access_Subtitle}
              </SubtitleContainer>
            )}
            <Button href={quickAccessPanel.attributes.Quick_Panel_Access_CTA_Link} target="_blank">
              {quickAccessPanel.attributes.Quick_Panel_Access_CTA_Text}
            </Button>
          </BoxContainer>
        ))}
      </DataWrapper>
    </GlobalWrapper>
  );
};

export default QuickAccessPanel;
