import { COLORS } from '@global/styles/colors';
import { DESKTOP_1280, MOBILE_575, TABLET_768, TABLET_992 } from '@global/styles/sizes';
import styled, { css } from 'styled-components';

export const FilterContainer = styled.div`
  width: 206px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (max-width: ${DESKTOP_1280}px) {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column: -1 / 1;
    align-items: center;
  }
  @media (max-width: ${MOBILE_575}px) {
    grid-template-columns: 1fr;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  height: 36px;
  padding: 6px 40px 6px 15px;
  border-radius: 50px;
  border: 1px solid #d5d5d5;
  box-sizing: border-box;
  position: relative;
  font-family: 'Poppins';
  font-size: 16px;
  line-height: 25px;

  &::placeholder {
    color: #000000;
  }

  @media (max-width: ${DESKTOP_1280}px) {
    width: 100%;
  }
`;

export const SearchContainer = styled.div`
  margin-bottom: 4px;
  position: relative;

  &:hover {
    #searchInput {
      background-color: #e2e8f0;
    }
  }
`;

export const SearchImage = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 16px;
  top: 8px;
  cursor: pointer;
`;

export const FilterWrapper = styled.div<{ renderFilters: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: ${DESKTOP_1280}px) {
    display: ${({ renderFilters }) => (renderFilters ? 'flex' : 'none')};
    grid-column: 1/-1;
  }
`;

export const TitleFilterContainer = styled.div<{ alignCenter?: boolean; isFilterOpen?: boolean }>`
  margin-bottom: ${({ isFilterOpen }) => (isFilterOpen ? '0' : '18px')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: ${({ alignCenter }) => (alignCenter ? 'center' : 'flex-end')};
  gap: 10px;
  cursor: pointer;

  &:hover {
    .filter-title {
      color: #0e8cb9;
    }
    .icon-container {
      svg {
        path {
          fill: #0e8cb9;
        }
      }
    }
  }
`;

export const FilterTitle = styled.div`
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #252a34;
`;

export const ClearFilter = styled.div`
  cursor: pointer;
  text-decoration: underline;
  font-family: 'Poppins';
  font-size: 14px;
  line-height: 21px;
  color: #00a8cf;

  &:hover {
    color: #0e8cb9;
  }
`;

export const IconContainer = styled.div<{ isOpen: boolean }>`
  width: 12px;
  height: 6px;
  display: flex;
  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `}
`;

export const CheckboxInput = styled.input`
  width: 16px;
  min-width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid #b9ccd8;
  margin-right: 8px;
  cursor: pointer;
  &:checked {
    accent-color: #0e8cb9;
  }
`;

export const FilterInput = styled.label<{ lastChild?: boolean }>`
  ${({ lastChild }) =>
    lastChild &&
    css`
      margin-bottom: 18px;
    `}
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  font-family: 'Poppins';
  font-size: 13px;
  line-height: 18px;
  color: #4b5366;
`;

export const FilterButton = styled.div`
  width: 230px;
  height: 48px;
  display: none;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-family: 'Poppins';
  font-size: 20px;
  line-height: 30px;
  color: ${COLORS.white};
  background-color: #0e8cb9;
  border: 1px solid #0e8cb9;
  cursor: pointer;
  &:hover {
    color: #0e8cb9;
    background-color: ${COLORS.white};
  }

  @media (max-width: ${DESKTOP_1280}px) {
    display: flex;
  }
  @media (max-width: ${MOBILE_575}px) {
    width: 100%;
  }
`;

export const Container = styled.div`
  width: 100%;
  margin: 50px 0 90px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  gap: 26px;

  @media (max-width: ${DESKTOP_1280}px) {
    flex-direction: column;
  }
`;

export const ArticleContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const ResultContainer = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
`;

export const ResultText = styled.div`
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  color: #000000;
`;

export const Tag = styled.div`
  min-width: 103px;
  min-height: 35px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #0792b1;
  text-decoration: none;
  position: relative;
  background-color: #e5f4f7;
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 11px;
`;

export const ArticleWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 26px;
`;

export const BoxContainer = styled.div`
  width: 31%;

  @media (max-width: ${TABLET_992}px) {
    width: 47%;
  }

  @media (max-width: ${TABLET_768}px) {
    width: 100%;
  }
`;

export const RemoveIconContainer = styled.div`
  width: 20px;
  height: 22px;
  margin-left: 6px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 22px;
  }

  &:hover {
    svg {
      path {
        fill: #0e8cb9;
      }
    }
  }
`;
