/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { GlobalWrapper } from '@global/styles/grid';
import ArrowIcon from '@sections/ArticlesList/assets/ArrowIcon';
import { ArticleType } from '@sections/ResourcesCenterHero/ResourcesCenterHero.types';
import RemoveIcon from '@sections/ArticlesList/assets/RemoveIcon';
import NoResultFilter from '@components/NoResultFilter/NoResultFilter';
import { ShareContainer } from '@sections/ArticlesList/articleList.styles';
import { Article, ArticleFilterProps } from './articleFilter.types';
import SearchIcon from '../../assets/search-icon.svg';
import {
  ArticleContainer,
  ArticleWrapper,
  BoxContainer,
  CheckboxInput,
  ClearFilter,
  RemoveIconContainer,
  Container,
  FilterButton,
  FilterContainer,
  FilterInput,
  FilterTitle,
  FilterWrapper,
  IconContainer,
  ResultContainer,
  ResultText,
  SearchContainer,
  SearchImage,
  SearchInput,
  Tag,
  TagsContainer,
  TitleFilterContainer,
} from './articleFilter.styles';
import ArticleCard from '../MaterialCard/MaterialCard';
import TrainingCard from '../TrainingCard/TrainingCard';
import CaseStudiesCard from '../CaseStudiesCard/CaseStudiesCard';
import BlogCard from '../BlogCard/BlogCard';

const ArticleFilter = ({
  materials,
  pathfulModuleFilter,
  filterApplied,
  typesFilter,
  handelFilterApplied,
  rolesFilter,
  topicsFilter,
  trainings,
  handleClearFilters,
  caseStudies,
  blogs,
  pathfulModuleFilterOpen,
  roleFilterOpen,
  setPathfulModuleFilterOpen,
  setRoleFilterOpen,
  setTopicFilterOpen,
  setTypeFilterOpen,
  topicFilterOpen,
  typeFilterOpen,
}: ArticleFilterProps) => {
  const [renderFilters, setRenderFilters] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const [confirmSearchInput, setConfirmSearchInput] = useState<string>('');
  const [renderArticles, setRenderArticles] = useState<Article[]>([]);
  const [renderTooltip, setRenderTooltip] = useState(false);

  const originalPublications = (): Article[] => {
    const publications: Article[] = [];

    (trainings || []).forEach((training) => {
      publications.push({ ...training, type: ArticleType.Training });
    });

    (materials || []).forEach((material) => {
      publications.push({ ...material, type: ArticleType.Material });
    });

    (caseStudies || []).forEach((caseStudy) => {
      publications.push({ ...caseStudy, type: ArticleType.CaseStudies });
    });

    (blogs || []).forEach((blog) => {
      publications.push({ ...blog, type: ArticleType.Blog });
    });

    return publications
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
  };

  const handleSearch = (arrayArticles: Article[]) => {
    if (confirmSearchInput !== '') {
      setRenderArticles(
        arrayArticles.filter((arrayArticle) =>
          arrayArticle.attributes.Title.toLowerCase().includes(confirmSearchInput.toLowerCase()),
        ),
      );
    } else {
      setRenderArticles([...new Set(arrayArticles)]);
    }
  };

  const handleFilterArticles = (arrayArticles: Article[]): Article[] => {
    const articlesToRender: Article[] = [];
    arrayArticles.forEach((article) => {
      if (article.attributes.Topics.data.length > 0) {
        article.attributes.Topics.data.forEach((topic: { attributes: { Topic: string } }) => {
          if (filterApplied.includes(topic.attributes.Topic)) {
            articlesToRender.push(article);
          }
        });
      }
      if (article.attributes.Roles.data.length > 0) {
        article.attributes.Roles.data.forEach((role: { attributes: { Role: string } }) => {
          if (filterApplied.includes(role.attributes.Role)) {
            articlesToRender.push(article);
          }
        });
      }
      if (article.attributes.Pathful_Modules.data.length > 0) {
        article.attributes.Pathful_Modules.data.forEach(
          (career: { attributes: { Pathful_Module: string } }) => {
            if (filterApplied.includes(career.attributes.Pathful_Module)) {
              articlesToRender.push(article);
            }
          },
        );
      }
      if (article.attributes.Type_Filters.data.length > 0) {
        article.attributes.Type_Filters.data.forEach((type: { attributes: { Type: string } }) => {
          if (filterApplied.includes(type.attributes.Type)) {
            articlesToRender.push(article);
          }
        });
      }
    });

    return articlesToRender;
  };

  useEffect(() => {
    if (filterApplied.length > 0) {
      const articlesToRender = handleFilterArticles(originalPublications());
      handleSearch(articlesToRender);
    } else if (confirmSearchInput !== '') {
      handleSearch(originalPublications());
    }
    if (filterApplied.length === 0 && confirmSearchInput === '') {
      setRenderArticles(originalPublications());
    }
  }, [filterApplied, confirmSearchInput]);

  useEffect(() => {
    const input = document.getElementById('searchInput') as HTMLInputElement;
    if (input) {
      input.onkeydown = (event) => {
        if (event.code === 'Enter') {
          event.preventDefault();
          setConfirmSearchInput(event.target?.value);
        }
      };
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setRenderTooltip(false);
    }, 3000);
  }, [renderTooltip]);
  return (
    <GlobalWrapper>
      <Container>
        <FilterContainer>
          <FilterButton onClick={() => setRenderFilters(!renderFilters)}>Filters</FilterButton>
          <ShareContainer>
            <SearchContainer>
              <SearchInput
                id="searchInput"
                placeholder="Search"
                type="text"
                value={searchInput}
                onChange={(event) => setSearchInput(event.target.value)}
              />
              <SearchImage
                src={SearchIcon}
                alt="Search"
                onClick={() => setConfirmSearchInput(searchInput)}
              />
            </SearchContainer>
            {/* turn off share
            <Share onClick={() => handleCopyToClipboard(filterApplied, setRenderTooltip)}>
              <ShareIcon />
              <ShareTooltip style={{ right: '-200%' }} renderTooltip={renderTooltip}>
                Copied Link!
              </ShareTooltip>
            </Share> */}
          </ShareContainer>
          <FilterWrapper renderFilters={renderFilters}>
            {(pathfulModuleFilter.length > 0 ||
              topicsFilter.length > 0 ||
              rolesFilter.length ||
              typesFilter.length > 0) && (
              <TitleFilterContainer>
                <FilterTitle>Filters</FilterTitle>
                <ClearFilter onClick={handleClearFilters}>Clear Filters</ClearFilter>
              </TitleFilterContainer>
            )}
            {pathfulModuleFilter.length > 0 && (
              <>
                <TitleFilterContainer
                  isFilterOpen={pathfulModuleFilterOpen}
                  alignCenter
                  onClick={() => setPathfulModuleFilterOpen(!pathfulModuleFilterOpen)}
                >
                  <FilterTitle className="filter-title">Pathful Module</FilterTitle>
                  <IconContainer className="icon-container" isOpen={pathfulModuleFilterOpen}>
                    <ArrowIcon />
                  </IconContainer>
                </TitleFilterContainer>
                {pathfulModuleFilterOpen &&
                  pathfulModuleFilter.map((module, index) => (
                    <FilterInput
                      key={module.attributes.Pathful_Module}
                      lastChild={pathfulModuleFilter.length - 1 === index}
                    >
                      <CheckboxInput
                        name={module.attributes.Pathful_Module}
                        value={module.attributes.Pathful_Module}
                        checked={filterApplied.includes(module.attributes.Pathful_Module)}
                        onChange={() => handelFilterApplied(module.attributes.Pathful_Module)}
                        type="checkbox"
                      />
                      {module.attributes.Pathful_Module}
                    </FilterInput>
                  ))}
              </>
            )}
            {typesFilter.length > 0 && (
              <>
                <TitleFilterContainer
                  isFilterOpen={typeFilterOpen}
                  alignCenter
                  onClick={() => setTypeFilterOpen(!typeFilterOpen)}
                >
                  <FilterTitle className="filter-title">Type Filter</FilterTitle>
                  <IconContainer className="icon-container" isOpen={typeFilterOpen}>
                    <ArrowIcon />
                  </IconContainer>
                </TitleFilterContainer>
                {typeFilterOpen &&
                  typesFilter.map((type, index) => (
                    <FilterInput
                      key={type.attributes.Type}
                      lastChild={typesFilter.length - 1 === index}
                    >
                      <CheckboxInput
                        name={type.attributes.Type}
                        value={type.attributes.Type}
                        checked={filterApplied.includes(type.attributes.Type)}
                        onChange={() => handelFilterApplied(type.attributes.Type)}
                        type="checkbox"
                      />
                      {type.attributes.Type}
                    </FilterInput>
                  ))}
              </>
            )}
            {topicsFilter.length > 0 && (
              <>
                <TitleFilterContainer
                  isFilterOpen={topicFilterOpen}
                  alignCenter
                  onClick={() => setTopicFilterOpen(!topicFilterOpen)}
                >
                  <FilterTitle className="filter-title">Topics</FilterTitle>
                  <IconContainer className="icon-container" isOpen={topicFilterOpen}>
                    <ArrowIcon />
                  </IconContainer>
                </TitleFilterContainer>
                {topicFilterOpen &&
                  topicsFilter.map((topic, index) => (
                    <FilterInput
                      key={topic.attributes.Topic}
                      lastChild={topicsFilter.length - 1 === index}
                    >
                      <CheckboxInput
                        name={topic.attributes.Topic}
                        value={topic.attributes.Topic}
                        checked={filterApplied.includes(topic.attributes.Topic)}
                        onChange={() => handelFilterApplied(topic.attributes.Topic)}
                        type="checkbox"
                      />
                      {topic.attributes.Topic}
                    </FilterInput>
                  ))}
              </>
            )}
            {rolesFilter.length > 0 && (
              <>
                <TitleFilterContainer
                  isFilterOpen={roleFilterOpen}
                  alignCenter
                  onClick={() => setRoleFilterOpen(!roleFilterOpen)}
                >
                  <FilterTitle className="filter-title">Role</FilterTitle>
                  <IconContainer className="icon-container" isOpen={roleFilterOpen}>
                    <ArrowIcon />
                  </IconContainer>
                </TitleFilterContainer>
                {roleFilterOpen &&
                  rolesFilter.map((role, index) => (
                    <FilterInput
                      key={role.attributes.Role}
                      lastChild={rolesFilter.length - 1 === index}
                    >
                      <CheckboxInput
                        name={role.attributes.Role}
                        value={role.attributes.Role}
                        checked={filterApplied.includes(role.attributes.Role)}
                        onChange={() => handelFilterApplied(role.attributes.Role)}
                        type="checkbox"
                      />
                      {role.attributes.Role}
                    </FilterInput>
                  ))}
              </>
            )}
          </FilterWrapper>
        </FilterContainer>
        <ArticleContainer>
          {filterApplied.length > 0 && (
            <ResultContainer>
              <ResultText>Results for:</ResultText>
              <TagsContainer>
                {filterApplied.map((filter) => (
                  <Tag key={filter}>
                    {filter}
                    <RemoveIconContainer onClick={() => handelFilterApplied(filter)}>
                      <RemoveIcon />
                    </RemoveIconContainer>
                  </Tag>
                ))}
              </TagsContainer>
            </ResultContainer>
          )}
          <ArticleWrapper>
            {renderArticles.length > 0 ? (
              renderArticles.map((article) => {
                switch (article.type) {
                  case ArticleType.Material:
                    return (
                      <BoxContainer key={article.id}>
                        <ArticleCard material={article} />
                      </BoxContainer>
                    );
                  case ArticleType.Training:
                    return (
                      <BoxContainer key={article.id}>
                        <TrainingCard training={article} />
                      </BoxContainer>
                    );
                  case ArticleType.CaseStudies:
                    return (
                      <BoxContainer key={article.id}>
                        <CaseStudiesCard caseStudie={article} />
                      </BoxContainer>
                    );
                  case ArticleType.Blog:
                    return (
                      <BoxContainer key={article.id}>
                        <BlogCard blog={article} />
                      </BoxContainer>
                    );
                  default:
                    return null;
                }
              })
            ) : (
              <NoResultFilter />
            )}
          </ArticleWrapper>
        </ArticleContainer>
      </Container>
    </GlobalWrapper>
  );
};

export default ArticleFilter;
