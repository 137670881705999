import { COLORS } from '@global/styles/colors';
import { FONT } from '@global/styles/default';
import { TABLET_768, TABLET_992 } from '@global/styles/sizes';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #000000;
`;

export const TitleContainer = styled.h1`
  margin: 19px 0 32px;
  font-family: ${FONT.ulmGrotesk};
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  color: ${COLORS.white};
`;

export const CaseStudieContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 20px;

  @media (max-width: ${TABLET_768}px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const HeroWrapper = styled.div`
  width: 98%;
  height: 100%;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  gap: 25px;

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
  }

  @media (max-width: ${TABLET_768}px) {
    display: flex;
    flex-direction: column;
  }
`;

export const CaseStudieWrapper = styled.a`
  width: 100%;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  box-shadow: 0px 0px 0px 1px ${COLORS.white};
  border-radius: 8px;

  &:hover {
    .data-container {
      background-color: #e2e8f0;
    }
  }
`;

export const CaseStudieTitle = styled.div`
  min-height: 72px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
`;
export const CaseStudieTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  &:hover {
    .material-title-tooltip {
      display: flex;
    }
  }
`;

export const CaseStudieTitleTooltip = styled.div`
  width: 100%;
  padding: 10px;
  bottom: 54%;
  left: 10%;
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  text-decoration: none;
  background-color: ${COLORS.white};
`;

export const DataContainer = styled.div`
  width: 50%;
  padding: 16px;
  border-radius: 8px 0 0 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
  background-color: ${COLORS.white};
`;

export const ImageContainer = styled.div`
  width: 50%;
  border-radius: 0 8px 8px 0;

  img {
    height: 100% !important;
    border-radius: 0 8px 8px 0;
    object-fit: cover;
    object-position: left;
  }
`;

export const CaseStudieTopic = styled.a`
  width: max-content;
  font-family: 'Poppins';
  font-size: 12px;
  line-height: 16px;
  color: #0e8cb9;
  text-decoration: none;
`;

export const CaseStudiesTopicContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
`;

export const Tag = styled.p`
  color: #93357f;
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
  text-align: left;
`;
