import { COLORS } from '@global/styles/colors';
import { FONT } from '@global/styles/default';
import { TABLET_768, TABLET_992 } from '@global/styles/sizes';
import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 44px;
  display: flex;
  flex-direction: column;
  background-color: #000000;
`;

export const TitleContainer = styled.h1`
  margin: 19px 0 32px;
  font-family: ${FONT.ulmGrotesk};
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  color: ${COLORS.white};
`;

export const BlogContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  grid-template-rows: repeat(2, 2fr);
  gap: 20px;

  @media (max-width: ${TABLET_992}px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
  }
`;

export const HeroWrapper = styled.div`
  width: 98%;
  height: 100%;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  gap: 25px;

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
  }

  @media (max-width: ${TABLET_768}px) {
    display: flex;
    flex-direction: column;
  }
`;

export const BlogWrapper = styled.a`
  width: 100%;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  box-shadow: 0px 0px 0px 1px ${COLORS.white};
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    .data-container {
      background-color: #e2e8f0;
    }
  }
`;

export const Tag = styled.p`
  color: #f48f28;
  font-size: 16px;
  font-family: 'Poppins';
  font-weight: bold;
  line-height: 21px;
  text-align: left;
`;

export const BlogTitle = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: ${COLORS.black};
`;

export const BlogTitleContainer = styled.div`
  height: 75%;
  position: relative;
`;

export const BlogPublicationDate = styled.div`
  margin-top: 4px;
  font-family: 'Poppins';
  font-size: 16px;
  line-height: 26px;
  color: #0e8cb9;
`;

export const DataContainer = styled.div`
  width: 60%;
  padding: 16px;
  border-radius: 8px 0 0 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
  background-color: ${COLORS.white};
`;

export const ImageContainer = styled.div`
  width: 40%;
  height: 100%;
  border-radius: 0 8px 8px 0;

  img {
    height: 100% !important;
    border-radius: 0 8px 8px 0;
    object-fit: cover;
    object-position: left;
  }
`;

export const BlogTopic = styled.a`
  width: max-content;
  margin-top: 18px;
  font-family: 'Poppins';
  font-size: 12px;
  line-height: 20px;
  color: #0e8cb9;
  text-decoration: none;
`;
