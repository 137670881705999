import { AllFiltersProps } from '@sections/BlogList/blogList.types';

interface AllFiltersOpenProps {
  setTopicFilterOpen: (value: boolean) => void;
  setRoleFilterOpen: (value: boolean) => void;
  setPathfulModuleFilterOpen: (value: boolean) => void;
  setTypeFilterOpen: (value: boolean) => void;
}

const handlePrimaryFieldFilterViaQueryParam = (
  filter: string,
  filters: AllFiltersProps,
  setFiltersOpen: AllFiltersOpenProps,
  setFilterApplied: (value: string[]) => void,
) => {
  const roles = filters.rolesFilter.filter((role) => role.attributes.Role === filter);
  const topic = filters.topicsFilter.filter(
    (topicFilter) => topicFilter.attributes.Topic === filter,
  );
  const career = filters.pathfulModulesFilter.filter(
    (module) => module.attributes.Pathful_Module === filter,
  );
  const type = filters.typesFilter.filter((typeFilter) => typeFilter.attributes.Type === filter);

  if (topic.length > 0) {
    setFiltersOpen.setTopicFilterOpen(true);
  }
  if (roles.length > 0) {
    setFiltersOpen.setRoleFilterOpen(true);
  }
  if (career.length > 0) {
    setFiltersOpen.setPathfulModuleFilterOpen(true);
  }
  if (type.length > 0) {
    setFiltersOpen.setTypeFilterOpen(true);
  }
  setFilterApplied(filter.split(','));
};

export const handleFilterViaQueryParam = (
  urlParams: URLSearchParams,
  filters: AllFiltersProps,
  setFiltersOpen: AllFiltersOpenProps,
  setFilterApplied: (value: string[]) => void,
) => {
  const primaryFieldFilter = urlParams.get('filter');

  if (primaryFieldFilter) {
    handlePrimaryFieldFilterViaQueryParam(
      primaryFieldFilter,
      filters,
      setFiltersOpen,
      setFilterApplied,
    );
  } else {
    const topic = urlParams.get('topic');
    const role = urlParams.get('role');
    const career = urlParams.get('career');
    const type = urlParams.get('types');

    if (topic) {
      setFilterApplied([topic]);
      setFiltersOpen.setTopicFilterOpen(true);
      return;
    }
    if (role) {
      setFilterApplied([role]);
      setFiltersOpen.setRoleFilterOpen(true);
      return;
    }
    if (career) {
      setFilterApplied([career]);
      setFiltersOpen.setPathfulModuleFilterOpen(true);
      return;
    }
    if (type) {
      setFilterApplied([type]);
      setFiltersOpen.setTypeFilterOpen(true);
    }
  }
};

export const handleCopyToClipboard = (
  filterApplied: string[],
  setRenderTooltip: (value: boolean) => void,
) => {
  let clipboardLink = window.location.href;
  if (filterApplied.length > 0) {
    clipboardLink = `${
      window.location.origin + window.location.pathname
    }?filter=${encodeURIComponent(filterApplied.toString())}`;
  }
  navigator.clipboard.writeText(clipboardLink);
  setRenderTooltip(true);
};
