import React from 'react';
import { GlobalWrapper } from '@global/styles/grid';
import { CMSParseImage } from '@components/StrapiComponents/ImageParser';
import { QuickAccessPanelProps } from './QuickAccessPanelIndex.types';
import {
  BoxContainer,
  Button,
  DataWrapper,
  ImageContainer,
  TextContainer,
  TitleContainer,
} from './QuickAccessPanelIndex.styles';

const QuickAccessPanelIndex = ({ data }: QuickAccessPanelProps) => {
  return (
    <GlobalWrapper>
      <DataWrapper>
        {data.map((quickAccessPanel) => (
          <BoxContainer isMainAccess={!!quickAccessPanel.attributes.Quick_Panel_Access_Subtitle}>
            <TextContainer>
              {quickAccessPanel.attributes.Quick_Panel_Access_Icon?.data && (
                <ImageContainer>
                  {CMSParseImage(quickAccessPanel.attributes.Quick_Panel_Access_Icon)}
                </ImageContainer>
              )}
              <TitleContainer>
                {quickAccessPanel.attributes.Quick_Panel_Access_Title}
              </TitleContainer>
              <Button
                href={quickAccessPanel.attributes.Quick_Panel_Access_CTA_Link}
                target="_blank"
              >
                {quickAccessPanel.attributes.Quick_Panel_Access_CTA_Text}
              </Button>
            </TextContainer>
          </BoxContainer>
        ))}
      </DataWrapper>
    </GlobalWrapper>
  );
};

export default QuickAccessPanelIndex;
