import React from 'react';
import { CMSParseImage } from '@components/StrapiComponents/ImageParser';
import { transformToSlug } from 'helpers/slugHelper';
import { PATHS } from '@global/constants/urls';

import {
  CaseStudieContainer,
  ImageContainer,
  DataContainer,
  Tag,
  TitleContainer,
  SubtitleContainer,
  CaseStudieTopic,
  TopicContainer,
} from './caseStudiesCard.styles';
import { CaseStudiesCardProps } from './caseStudiesCard.types';

const CaseStudiesCard = ({ caseStudie }: CaseStudiesCardProps) => {
  return (
    <CaseStudieContainer
      href={`${PATHS.CASESTUDIES}/${transformToSlug(caseStudie.attributes.slug.toLowerCase())}`}
    >
      <ImageContainer>
        <Tag>Case Study</Tag>
        {CMSParseImage(caseStudie.attributes.Small_Image.data)}
      </ImageContainer>
      <DataContainer className="case-studies-data">
        <TitleContainer>{caseStudie.attributes.Title}</TitleContainer>
        <SubtitleContainer>{caseStudie.attributes.Subtitle}</SubtitleContainer>
        <TopicContainer>
          <CaseStudieTopic
            href={`${PATHS.RESOURCE_CENTER}/?filter=${caseStudie.attributes?.Primary_Field || ''}`}
          >
            {caseStudie.attributes?.Primary_Field}
          </CaseStudieTopic>
          <CaseStudieTopic
            href={`${PATHS.RESOURCE_CENTER}/?filter=${
              caseStudie.attributes?.Secondary_Field || ''
            }`}
          >
            {caseStudie.attributes?.Secondary_Field}
          </CaseStudieTopic>
        </TopicContainer>
      </DataContainer>
    </CaseStudieContainer>
  );
};

export default CaseStudiesCard;
