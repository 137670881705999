import { COLORS } from '@global/styles/colors';
import { FONT } from '@global/styles/default';
import { DESKTOP_1024, TABLET_768, TABLET_992 } from '@global/styles/sizes';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding-bottom: 44px;
  display: flex;
  flex-direction: column;
  background-color: #000000;
`;

export const TitleContainer = styled.h1`
  margin: 19px 0 32px;
  font-family: ${FONT.ulmGrotesk};
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  color: ${COLORS.white};
`;

export const WebinarContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 20px;

  @media (max-width: ${TABLET_768}px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const WebinarWrapper = styled.a<{ isLastWebinar?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  box-shadow: 0px 0px 0px 1px ${COLORS.white};
  border-radius: 8px;

  &:hover {
    .data-container {
      background-color: #e2e8f0;
    }
  }

  @media (max-width: ${TABLET_992}px) {
    grid-column-start: 1;
    ${({ isLastWebinar }) =>
      isLastWebinar &&
      css`
        grid-column-start: 2;
      `}
  }
`;

export const WebinarTitleContainer = styled.div`
  height: 100%;
  padding-top: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const WebinarTitle = styled.div`
  min-height: 68px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: ${COLORS.black};
  position: relative;
`;

export const DataContainer = styled.div`
  width: 60%;
  padding-inline: 16px;
  padding-block: 10px;
  border-radius: 8px 0 0 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: ${COLORS.white};
  position: relative;
`;

export const ImageContainer = styled.div`
  width: 40%;
  border-radius: 0 8px 8px 0;

  img {
    height: 100% !important;
    object-position: left;
    border-radius: 0 8px 8px 0;
    object-fit: cover;
  }
`;

export const MainContainer = styled.div`
  width: 93%;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.violetRed};
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    height: max-content;
    box-sizing: border-box;
    grid-column-start: 2;
    grid-row-end: 2;
  }

  @media (max-width: ${TABLET_768}px) {
    width: 100%;
    box-sizing: border-box;
  }
`;

export const AllWebinarsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AllWebinarTitle = styled.div`
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: ${COLORS.white};
`;

export const AllWebinarText = styled.div`
  font-family: 'Poppins';
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.white};
`;

export const HeroWrapper = styled.div`
  width: 98%;
  height: 100%;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  gap: 25px;

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
  }

  @media (max-width: ${TABLET_768}px) {
    display: flex;
    flex-direction: column;
  }
`;

export const AllWebinarCTA = styled.a`
  width: 226px;
  height: 50px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  text-decoration: none;
  border-radius: 8px;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.violetRed};
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: ${COLORS.violetRed};

  &:hover {
    color: ${COLORS.white};
    background-color: ${COLORS.violetRed};
    border: 1px solid ${COLORS.white};
  }
`;

export const CalendarContainer = styled.img`
  width: 82px;
  height: 87px;
  margin: 0 auto;
`;

export const WebinarSchedule = styled.div`
  height: 28px;
  padding-bottom: 10px;
  display: flex;
  align-items: flex-end;
  color: ${COLORS.resolutionBlue};
  font-size: 20px;
  line-height: 25px;
  font-family: 'Poppins';
  font-weight: Bold;
  border-bottom: 1px solid #d5d5d5;

  @media (max-width: ${DESKTOP_1024}px) {
    padding-bottom: 5px;
  }

  @media (max-width: ${TABLET_992}px) {
    height: 35px;
  }
`;

export const Tag = styled.p`
  color: #ee2a7b;
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
  text-align: left;
`;

export const PrincipalTopic = styled.p`
  font-family: 'Poppins';
  font-size: 12px;
  line-height: 16px;
  color: #0e8cb9;
  text-decoration: none;
  cursor: pointer;
`;
