import React, { useEffect, useState } from 'react';
import { GlobalWrapper } from '@global/styles/grid';
import { CMSParseImage } from '@components/StrapiComponents/ImageParser';
import { transformToSlug } from 'helpers/slugHelper';
import { PATHS } from '@global/constants/urls';
import { TrainingSchedule } from '@sections/ArticlesList/components/TrainingCard/trainingCard.styles';
import { handleSchedule } from 'helpers/trainingHelper';
import {
  ArticlesContainer,
  ArticleSubtitle,
  ArticleTitle,
  Container,
  ImageContainer,
  SubtitleContainer,
  Tag,
  TitleContainer,
  ArticleWrapper,
  DataContainerArticle,
  ArticleTitleContainer,
  ArticleTitleTooltip,
  TopicArticle,
  PrimaryRole,
  TopicsContainer,
} from './resourcesCenterHero.styles';
import { ArticleType, ResourcesCenterHeroProps } from './ResourcesCenterHero.types';

const ResourcesCenterHero = ({ data }: ResourcesCenterHeroProps) => {
  const [renderTooltip, setRenderTooltip] = useState<string[]>([]);

  const articles = [
    data.materials.map((material) => ({ ...material, type: ArticleType.Material })),
    data.trainings.map((training) => ({ ...training, type: ArticleType.Training })),
    data.blogs.map((blogs) => ({ ...blogs, type: ArticleType.Blog })),
    data.caseStudies.map((caseStudy) => ({ ...caseStudy, type: ArticleType.CaseStudies })),
  ];

  useEffect(() => {
    const isEllipsisActive = (element) => {
      return element.offsetHeight + 2 < element.scrollHeight;
    };

    const articlesTitle = document.querySelectorAll('.article-title');
    const renderTitleTooltip: string[] = [];
    articlesTitle.forEach((el) => {
      if (isEllipsisActive(el)) {
        renderTitleTooltip.push(el.innerText);
      }
    });
    setRenderTooltip(renderTitleTooltip);
  }, []);

  return (
    <Container>
      <GlobalWrapper>
        <TitleContainer>Resource Center</TitleContainer>
        <SubtitleContainer>
          Unlock the full potential of Pathful with our extensive library of user resources.
        </SubtitleContainer>
        <ArticlesContainer>
          {articles
            .flat()
            .slice(0, 3)
            .map((article, index) => {
              if (article.type === ArticleType.Material) {
                return (
                  <ArticleWrapper
                    isFirstArticle={index === 0}
                    href={`${PATHS.MATERIAL}/${transformToSlug(
                      article.attributes.slug.toLowerCase(),
                    )}`}
                  >
                    <DataContainerArticle isFirstArticle={index === 0} className="data-container">
                      <Tag
                        isFirstArticle={index === 0}
                        type={ArticleType.Material}
                        colorTag={article.attributes.Tag.data.attributes.Text_Color}
                      >
                        {article.attributes.Tag.data.attributes.Type}
                      </Tag>
                      <ArticleTitleContainer>
                        {index === 0 && (
                          <PrimaryRole>
                            {article.attributes.Primary_Role.data.attributes.Role}
                          </PrimaryRole>
                        )}
                        <ArticleTitle className="article-title" isFirstArticle={index === 0}>
                          {article.attributes.Title}
                        </ArticleTitle>
                        {renderTooltip.includes(article.attributes.Title) && (
                          <ArticleTitleTooltip className="article-title-tooltip">
                            {article.attributes.Title}
                          </ArticleTitleTooltip>
                        )}
                      </ArticleTitleContainer>
                      {index === 0 && (
                        <ArticleSubtitle>{article.attributes.Subtitle}</ArticleSubtitle>
                      )}
                      <TopicArticle
                        isFirstArticle={index === 0}
                        href={`${PATHS.RESOURCE_CENTER}/?filter=${article.attributes.Primary_Field}`}
                      >
                        {article.attributes.Primary_Field}
                      </TopicArticle>
                    </DataContainerArticle>
                    <ImageContainer isFirstArticle={index === 0}>
                      {CMSParseImage(article.attributes.Small_Image)}
                    </ImageContainer>
                  </ArticleWrapper>
                );
              }

              if (article.type === ArticleType.Training) {
                return (
                  <ArticleWrapper
                    isFirstArticle={index === 0}
                    href={`${PATHS.TRAINING}/${transformToSlug(
                      article.attributes.slug.toLowerCase(),
                    )}`}
                  >
                    <DataContainerArticle isFirstArticle={index === 0} className="data-container">
                      <Tag isFirstArticle={index === 0} type={ArticleType.Training}>
                        Training
                      </Tag>
                      <TrainingSchedule>
                        {handleSchedule(article.attributes.Schedule, true)}
                      </TrainingSchedule>
                      <ArticleTitleContainer>
                        <ArticleTitle
                          className="article-title"
                          isFirstArticle={index === 0}
                          isTrainingType
                        >
                          {article.attributes.Title}
                        </ArticleTitle>
                        {renderTooltip.includes(article.attributes.Title) && (
                          <ArticleTitleTooltip className="article-title-tooltip">
                            {article.attributes.Title}
                          </ArticleTitleTooltip>
                        )}
                      </ArticleTitleContainer>
                      {index === 0 && (
                        <ArticleSubtitle>{article.attributes.Subtitle}</ArticleSubtitle>
                      )}
                      <TopicArticle
                        isFirstArticle={index === 0}
                        href={`${PATHS.RESOURCE_CENTER}/?filter=${article.attributes.Primary_Field}`}
                      >
                        {article.attributes.Primary_Field}
                      </TopicArticle>
                    </DataContainerArticle>
                    <ImageContainer isFirstArticle={index === 0}>
                      {CMSParseImage(article.attributes.Small_Image)}
                    </ImageContainer>
                  </ArticleWrapper>
                );
              }

              if (article.type === ArticleType.Blog) {
                return (
                  <ArticleWrapper
                    isFirstArticle={index === 0}
                    href={`${PATHS.BLOG}/${transformToSlug(article.attributes.slug.toLowerCase())}`}
                  >
                    <DataContainerArticle isFirstArticle={index === 0} className="data-container">
                      <Tag isFirstArticle={index === 0} type={ArticleType.Blog}>
                        Blog
                      </Tag>
                      <ArticleTitleContainer>
                        <ArticleTitle className="article-title" isFirstArticle={index === 0}>
                          {article.attributes.Title}
                        </ArticleTitle>
                        {renderTooltip.includes(article.attributes.Title) && (
                          <ArticleTitleTooltip className="article-title-tooltip">
                            {article.attributes.Title}
                          </ArticleTitleTooltip>
                        )}
                      </ArticleTitleContainer>
                      {index === 0 && (
                        <ArticleSubtitle>{article.attributes.Subtitle}</ArticleSubtitle>
                      )}
                      <TopicArticle
                        isFirstArticle={index === 0}
                        isBlogOrCaseStudiesType
                        href={`${PATHS.RESOURCE_CENTER}/?filter=${article.attributes.Primary_Field}`}
                      >
                        {article.attributes.Primary_Field}
                      </TopicArticle>
                    </DataContainerArticle>
                    <ImageContainer isFirstArticle={index === 0}>
                      {CMSParseImage(article.attributes.Small_Image)}
                    </ImageContainer>
                  </ArticleWrapper>
                );
              }
              if (article.type === ArticleType.CaseStudies) {
                return (
                  <ArticleWrapper
                    isFirstArticle={index === 0}
                    href={`${PATHS.CASESTUDIES}/${transformToSlug(
                      article.attributes.slug.toLowerCase(),
                    )}`}
                  >
                    <DataContainerArticle
                      style={{ gap: index === 0 ? '6px' : '4px' }}
                      isFirstArticle={index === 0}
                      className="data-container"
                    >
                      <Tag isFirstArticle={index === 0} type={ArticleType.CaseStudies}>
                        Case Study
                      </Tag>
                      <ArticleTitleContainer>
                        <ArticleTitle className="article-title" isFirstArticle={index === 0}>
                          {article.attributes.Title}
                        </ArticleTitle>
                        {renderTooltip.includes(article.attributes.Title) && (
                          <ArticleTitleTooltip className="article-title-tooltip">
                            {article.attributes.Title}
                          </ArticleTitleTooltip>
                        )}
                      </ArticleTitleContainer>
                      {index === 0 && (
                        <ArticleSubtitle>{article.attributes.Subtitle}</ArticleSubtitle>
                      )}
                      <TopicsContainer>
                        <TopicArticle
                          style={{ lineHeight: '16px' }}
                          isBlogOrCaseStudiesType
                          isFirstArticle={index === 0}
                          href={`${PATHS.RESOURCE_CENTER}/?filter=${article.attributes.Primary_Field}`}
                        >
                          {article.attributes.Primary_Field}
                        </TopicArticle>
                        <TopicArticle
                          style={{ marginTop: '0', lineHeight: '16px' }}
                          isFirstArticle={index === 0}
                          href={`${PATHS.RESOURCE_CENTER}/?filter=${article.attributes.Secondary_Field}`}
                        >
                          {article.attributes.Secondary_Field}
                        </TopicArticle>
                      </TopicsContainer>
                    </DataContainerArticle>
                    <ImageContainer isFirstArticle={index === 0}>
                      {CMSParseImage(article.attributes.Small_Image)}
                    </ImageContainer>
                  </ArticleWrapper>
                );
              }
              return null;
            })}
        </ArticlesContainer>
      </GlobalWrapper>
    </Container>
  );
};

export default ResourcesCenterHero;
