import React from 'react';
import {
  SeparateLine,
  TrainingDate,
} from '@sections/ArticlesList/components/TrainingCard/trainingCard.styles';

export const handleSchedule = (schedule: string, turnOffDate?: boolean) => {
  const scheduleDate = new Date(schedule);
  const scheduleHours = scheduleDate.getHours();
  const scheduleMinutes = scheduleDate.getMinutes();
  const scheduleWeekDate = scheduleDate.toLocaleString('en-us', { weekday: 'long' });

  if (!Number.isNaN(scheduleHours) && !Number.isNaN(scheduleMinutes)) {
    return (
      <>
        {scheduleDate
          .toLocaleString('default', {
            month: 'short',
          })
          .toUpperCase()}{' '}
        {scheduleDate.getDate()}
        <TrainingDate>
          {!turnOffDate && (
            <>
              <SeparateLine>|</SeparateLine>
              {scheduleWeekDate}{' '}
            </>
          )}
          <SeparateLine>|</SeparateLine>
          {scheduleHours > 10 ? scheduleHours : `0${scheduleHours}`}:
          {scheduleMinutes > 10 ? scheduleMinutes : `0${scheduleMinutes}`}
        </TrainingDate>
      </>
    );
  }
  return <TrainingDate>{schedule}</TrainingDate>;
};
