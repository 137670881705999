/* eslint-disable no-nested-ternary */
import { COLORS } from '@global/styles/colors';
import { FONT } from '@global/styles/default';
import { DESKTOP_1024, DESKTOP_1280, MOBILE_575, TABLET_768 } from '@global/styles/sizes';
import styled, { css } from 'styled-components';
import { ArticleType } from './ResourcesCenterHero.types';

export const Container = styled.div`
  margin-bottom: 35px;
  display: flex;
  flex-direction: column;
  background-color: #000000;
`;

export const TitleContainer = styled.h1`
  margin: 19px 0 0px;
  font-family: ${FONT.ulmGrotesk};
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  color: ${COLORS.white};
`;

export const SubtitleContainer = styled.p`
  font-family: 'Poppins';
  font-size: 16px;
  line-height: 26px;
  color: ${COLORS.white};
`;

export const ArticlesContainer = styled.div`
  margin: 33px 0 50px;
  display: grid;
  grid-template-columns: 590px 1fr;
  grid-template-rows: auto;
  column-gap: 26px;
  row-gap: 21px;

  @media (max-width: ${DESKTOP_1280}px) {
    width: 98%;
  }

  @media (max-width: ${DESKTOP_1024}px) {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }

  @media (max-width: ${TABLET_768}px) {
    grid-template-columns: 1fr;
  }
`;

export const ArticleTitle = styled.div<{ isFirstArticle: boolean; isTrainingType?: boolean }>`
  max-height: ${({ isFirstArticle, isTrainingType }) =>
    isFirstArticle ? '105px' : isTrainingType ? '45px' : '69px'};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ isFirstArticle, isTrainingType }) =>
    isFirstArticle ? '4' : isTrainingType ? '2' : '3'};
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: ${({ isFirstArticle }) => (isFirstArticle ? '22px' : '18px')};
  line-height: ${({ isFirstArticle }) => (isFirstArticle ? '26px' : '22px')};
  color: ${COLORS.black};
`;

export const ArticleSubtitle = styled.div`
  height: 60px;
  margin-top: 4px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Poppins';
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.resolutionBlue};
`;

export const ArticlePublicationDate = styled.div`
  margin-top: 4px;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  line-height: 11px;
  color: ${COLORS.resolutionBlue};
`;

export const DataContainerArticle = styled.div<{ isFirstArticle: boolean }>`
  width: ${({ isFirstArticle }) => (isFirstArticle ? '60%' : '100%')};
  padding: ${({ isFirstArticle }) => (isFirstArticle ? '26px 20px 20px' : '16px 20px')};
  box-sizing: border-box;
  border-radius: 8px 0 0 8px;
  display: flex;
  gap: 6px;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background-color: ${COLORS.white};

  @media (max-width: ${DESKTOP_1024}px) {
    width: 60%;
  }

  @media (max-width: ${TABLET_768}px) {
    ${({ isFirstArticle }) =>
      isFirstArticle &&
      css`
        width: 100%;
        border-radius: 0 0 8px 8px;
      `};
  }
`;

export const ArticleWrapper = styled.a<{ isFirstArticle: boolean }>`
  width: 100%;
  height: ${({ isFirstArticle }) => (isFirstArticle ? '348px' : '162px')};
  display: flex;
  flex-direction: row;
  text-decoration: none;
  box-shadow: 0px 0px 0px 1px ${COLORS.white};
  border-radius: 8px;

  ${({ isFirstArticle }) =>
    isFirstArticle
      ? css`
          grid-column-start: 1;
          grid-row-start: 1;
          grid-row-end: 3;
        `
      : css`
          grid-column-start: 2;
        `}

  &:hover {
    .data-container {
      background-color: #e2e8f0;
    }
  }

  @media (max-width: ${DESKTOP_1024}px) {
    ${({ isFirstArticle }) =>
      isFirstArticle
        ? css`
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-end: 1;
          `
        : css`
            grid-column-start: auto;
          `}
  }

  @media (max-width: ${TABLET_768}px) {
    height: unset;
    grid-column-start: auto;
    grid-column-end: auto;
    grid-row-end: auto;
    flex-direction: ${({ isFirstArticle }) => (isFirstArticle ? 'column-reverse' : 'row')};
  }
`;

export const ImageContainer = styled.div<{ isFirstArticle: boolean }>`
  width: 50%;
  height: 100%;
  border-radius: 0 8px 8px 0;
  position: relative;

  div {
    height: inherit !important;
  }
  img {
    height: 100% !important;
    border-radius: 0 8px 8px 0;
    object-fit: cover;
    object-position: left;
  }

  @media (max-width: ${TABLET_768}px) {
    ${({ isFirstArticle }) =>
      isFirstArticle &&
      css`
        width: 100%;
        border-radius: 8px 8px 0 0;
        img {
          border-radius: 8px 8px 0 0;
        }
      `};
  }

  @media (max-width: ${MOBILE_575}px) {
    ${({ isFirstArticle }) =>
      !isFirstArticle &&
      css`
        width: 46%;
      `};
  }
`;

export const ArticleData = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-betweveneen;
  align-items: flex-end;
  gap: 20px;

  @media (max-width: ${TABLET_768}px) {
    margin-top: 15px;
    align-items: flex-start;
    gap: 10px;
  }
`;

export const ArticleTimeToRead = styled.div`
  display: flex;
  font-family: 'Poppins';
  font-size: 16px;
  line-height: 10px;
  color: #000000;
`;

export const Tag = styled.p<{ type: ArticleType; isFirstArticle: boolean; colorTag?: string }>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
  color: ${({ type, colorTag }) => {
    switch (type) {
      case ArticleType.Blog:
        return '#f48f28';
      case ArticleType.Material:
        return colorTag || '#009440';
      case ArticleType.Training:
        return '#EE2A7B';
      case ArticleType.CaseStudies:
        return '#943580';
      default:
        return COLORS.violetRed;
    }
  }};
  border-radius: ${({ isFirstArticle }) => (isFirstArticle ? '8px' : '0 8px 0 8px')};
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
`;

export const ArticleTitleContainer = styled.div`
  padding-top: 5px;
  box-sizing: border-box;
  position: relative;

  @media (max-width: ${DESKTOP_1024}px) {
    padding-top: 0;
  }

  &:hover {
    .article-title-tooltip {
      display: flex;
    }
  }
`;

export const ArticleTitleTooltip = styled.div`
  width: 100%;
  padding: 10px;
  bottom: 54%;
  left: 10%;
  z-index: 4;
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  text-decoration: none;
  background-color: ${COLORS.white};
`;

export const TopicArticle = styled.a<{
  isFirstArticle: boolean;
  isBlogOrCaseStudiesType?: boolean;
}>`
  margin-top: ${({ isFirstArticle, isBlogOrCaseStudiesType }) =>
    isFirstArticle ? (isBlogOrCaseStudiesType ? '72px' : '42px') : '0'};
  font-family: 'Poppins';
  font-size: 12px;
  line-height: 20px;
  color: #0e8cb9;
  text-decoration: none;
`;

export const PrimaryRole = styled.p`
  margin: 4px 0 6px;
  font-family: 'Poppins';
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.black};
`;

export const TopicsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
