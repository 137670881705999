import { COLORS } from '@global/styles/colors';
import { DESKTOP_1280 } from '@global/styles/sizes';
import styled from 'styled-components';

export const BlogContainer = styled.a`
  width: min-content;
  min-height: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  &:hover {
    .Blog-data {
      background-color: #f2f7fb;
    }
  }

  @media (max-width: ${DESKTOP_1280}px) {
    width: 100%;
  }
`;

export const ImageContainer = styled.div`
  width: 275px;
  height: 140px;
  position: relative;
  border-radius: 8px 8px 0 0;

  img {
    height: 140px !important;
    border-radius: 8px 8px 0 0;
    object-fit: cover;
    object-position: left;
  }

  @media (max-width: ${DESKTOP_1280}px) {
    width: 100%;
  }
`;

export const Tag = styled.div`
  width: 76px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background-color: #f48f28;
  border-radius: 0 8px 0 8px;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: ${COLORS.white};
`;

export const DataContainer = styled.div`
  width: 241px;
  min-height: 206px;
  padding: 20px 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 6px;
  border-radius: 0 0 8px 8px;
  border: 1px solid #d5d5d5;
  border-top: none;

  @media (max-width: ${DESKTOP_1280}px) {
    width: 100%;
    min-height: 241px;
    box-sizing: border-box;
  }
`;

export const TitleContainer = styled.div`
  max-height: 78px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Poppins';
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
  color: #000000;
  flex-shrink: 0;
`;

export const SubtitleContainer = styled.div`
  min-height: 60px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Poppins';
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.resolutionBlue};
`;

export const MoreInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PublicationDate = styled.p`
  font-size: 14px;
  font-weight: 400;
  font-family: 'Poppins';
  color: #161616;
  margin-bottom: 10px;
`;

export const Separator = styled.div`
  width: 100%;
  height: 2px;
  background-color: #d5d5d5;
  margin-bottom: 5px;
`;

export const BlogTitleContainer = styled.div`
  min-height: 52px;
  position: relative;

  &:hover {
    .Blog-title-tooltip {
      display: flex;
    }
  }
`;

export const BlogTopic = styled.a`
  width: max-content;
  min-height: 20px;
  font-family: 'Poppins';
  font-size: 12px;
  line-height: 20px;
  color: #0e8cb9;
  text-decoration: none;
`;
