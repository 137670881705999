import React, { useEffect, useState } from 'react';
import { GlobalWrapper } from '@global/styles/grid';
import { Markdown } from '@components/StrapiComponents';
import ShareIcon from '@sections/TrainingTitle/assets/share';
import { PATHS } from '@global/constants/urls';
import { CaseStudiesTitleProps } from './CaseStudiesTitle.types';
import {
  Author,
  CaseStudiesInfo,
  SeparateLine,
  SubtitleContainer,
  TitleContainer,
  PublishedAt,
  Share,
  CaseStudiesInfoWrapper,
  TagsContainer,
  Tag,
  ShareTooltip,
} from './CaseStudiesTitle.styles';

const CaseStudiesTitle = ({
  title,
  subtitle,
  author,
  publishedAt,
  roles,
  topics,
  careers,
  types,
}: CaseStudiesTitleProps) => {
  const [renderTooltip, setRenderTooltip] = useState(false);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    setRenderTooltip(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setRenderTooltip(false);
    }, 3000);
  }, [renderTooltip]);

  return (
    <GlobalWrapper>
      <TitleContainer>
        <Markdown data={{ content: title }} />
        <CaseStudiesInfo>
          <CaseStudiesInfoWrapper>
            <PublishedAt>{publishedAt}</PublishedAt>
            <SeparateLine>|</SeparateLine>
            <Author href="#authorSection">{author}</Author>
          </CaseStudiesInfoWrapper>
          <CaseStudiesInfoWrapper>
            <Share onClick={handleCopyToClipboard}>
              <ShareIcon />
              <ShareTooltip renderTooltip={renderTooltip}>Copied Link!</ShareTooltip>
            </Share>
          </CaseStudiesInfoWrapper>
        </CaseStudiesInfo>
      </TitleContainer>
      {(careers.data.length > 0 ||
        topics.data.length > 0 ||
        roles.data.length > 0 ||
        types.data.length > 0) && (
        <TagsContainer>
          {topics.data.map((topic) => (
            <Tag href={`${PATHS.CASESTUDIES}?topic=${topic.attributes.Topic}`}>
              {topic.attributes.Topic}
            </Tag>
          ))}
          {roles.data.map((role) => (
            <Tag href={`${PATHS.CASESTUDIES}?role=${role.attributes.Role}`}>
              {role.attributes.Role}
            </Tag>
          ))}
          {careers.data.map((career) => (
            <Tag href={`${PATHS.CASESTUDIES}?career=${career.attributes.Pathful_Module}`}>
              {career.attributes.Pathful_Module}
            </Tag>
          ))}
          {types.data.map((type) => (
            <Tag href={`${PATHS.CASESTUDIES}?types=${type.attributes.Type}`}>
              {type.attributes.Type}
            </Tag>
          ))}
        </TagsContainer>
      )}
      <SubtitleContainer>
        <Markdown data={{ content: subtitle }} />
      </SubtitleContainer>
    </GlobalWrapper>
  );
};

export default CaseStudiesTitle;
