import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GlobalWrapper } from '@global/styles/grid';
import { PATHS } from '@global/constants/urls';
import { SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import {
  Container,
  FiltersContainer,
  FilterWrapper,
  TitleFilterContainer,
  FilterTitle,
  IconContainer,
  FilterInput,
  CheckboxInput,
  FilterButton,
  FilterItemsList,
  ArticlesContainer,
  ArticleSection,
  Carrousel,
  ArticleViewMore,
  ArticleTitle,
  ArrowNavigationNext,
  ArrowNavigationPrev,
  FilterContainer,
  CarrouselContainer,
  SearchContainer,
  SearchInput,
  SearchImage,
} from './articleList.styles';
import ArrowIcon from './assets/ArrowIcon';
import { ArticleListProps } from './articleList.types';
import CarrouselIcon from './assets/CarrouselIcon';
import TrainingCard from './components/TrainingCard/TrainingCard';
import MaterialCard from './components/MaterialCard/MaterialCard';
import CaseStudiesCard from './components/CaseStudiesCard/CaseStudiesCard';
import BlogCard from './components/BlogCard/BlogCard';
import SearchIcon from './assets/search-icon.svg';

SwiperCore.use([Navigation, Pagination]);

const ArticleList = ({
  topicsFilter,
  pathfulModuleFilter,
  rolesFilter,
  trainings,
  materials,
  blogs,
  filterApplied,
  caseStudies,
  typesFilter,
  handelFilterApplied,
}: ArticleListProps) => {
  const [pathfulModuleFilterOpen, setPathfulModuleFilterOpen] = useState<boolean>(false);
  const [topicFilterOpen, setTopicFilterOpen] = useState<boolean>(false);
  const [typeFilterOpen, setTypeFilterOpen] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState('');
  const [roleFilterOpen, setRoleFilterOpen] = useState<boolean>(false);
  const [renderFilters, setRenderFilters] = useState(false);
  const [swiperRef, setSwiperRef] = useState<SwiperCore>();
  const [swiperRefTraining, setSwiperRefTraining] = useState<SwiperCore>();
  const [swiperRefCaseStudies, setSwiperRefCaseStudies] = useState<SwiperCore>();
  const [swiperRefBlog, setSwiperRefBlog] = useState<SwiperCore>();
  const [isNextArrowRender, setIsNextArrowRender] = useState(true);
  const [isPrevArrowRender, setIsPrevArrowRender] = useState(false);
  const [isPrevArrowRenderTraining, setIsPrevArrowRenderTraining] = useState(false);
  const [isPrevArrowRenderBlog, setIsPrevArrowRenderBlog] = useState(false);
  const [isNextArrowRenderTraining, setIsNextArrowRenderTraining] = useState(true);
  const [isPrevArrowRenderCaseStudies, setIsPrevArrowRenderCaseStudies] = useState(false);
  const [isNextArrowRenderCaseStudies, setIsNextArrowRenderCaseStudies] = useState(true);
  const [isNextArrowRenderBlog, setIsNextArrowRenderBlog] = useState(true);

  const [filteredMaterials, setFilteredMaterials] = useState(materials);
  const [filteredBlogs, setFilteredBlogs] = useState(blogs);
  const [filteredTrainings, setFilteredTrainings] = useState(trainings);
  const [filteredCaseStudies, setFilteredCaseStudies] = useState(caseStudies);

  const filterWrapperRef = useRef<HTMLDivElement>(null);

  const handleTrainingsCarrousel = () => {
    if (filteredTrainings.length > 5) {
      return filteredTrainings.slice(filteredTrainings.length - 5, filteredTrainings.length);
    }
    return filteredTrainings;
  };

  const handleBlogsCarrousel = () => {
    if (filteredBlogs.length > 5) {
      return filteredBlogs.slice(filteredBlogs.length - 5, filteredBlogs.length);
    }
    return filteredBlogs;
  };

  const handleRenderArrows = useCallback(() => {
    if (swiperRef && swiperRef.activeIndex > 0) {
      if (swiperRef.activeIndex >= 2 && swiperRef.isEnd) {
        setIsNextArrowRender(false);
      } else {
        setIsNextArrowRender(true);
      }
      setIsPrevArrowRender(true);
    } else {
      if (swiperRef.isEnd) {
        setIsNextArrowRender(false);
      } else {
        setIsNextArrowRender(true);
      }
      setIsPrevArrowRender(false);
    }
  }, [swiperRef]);

  const handleRenderArrowsTrainings = useCallback(() => {
    if (swiperRefTraining && swiperRefTraining.activeIndex > 0) {
      if (swiperRefTraining.activeIndex >= 2 && swiperRefTraining.isEnd) {
        setIsNextArrowRenderTraining(false);
      } else {
        setIsNextArrowRenderTraining(true);
      }
      setIsPrevArrowRenderTraining(true);
    } else {
      if (swiperRefTraining.isEnd) {
        setIsNextArrowRenderTraining(false);
      } else {
        setIsNextArrowRenderTraining(true);
      }
      setIsPrevArrowRenderTraining(false);
    }
  }, [swiperRefTraining]);

  const handleRenderArrowsCaseStudies = useCallback(() => {
    if (swiperRefCaseStudies && swiperRefCaseStudies.activeIndex > 0) {
      if (swiperRefCaseStudies.activeIndex >= 2 && swiperRefCaseStudies.isEnd) {
        setIsNextArrowRenderCaseStudies(false);
      } else {
        setIsNextArrowRenderCaseStudies(true);
      }
      setIsPrevArrowRenderCaseStudies(true);
    } else {
      if (swiperRefCaseStudies.isEnd) {
        setIsNextArrowRenderCaseStudies(false);
      } else {
        setIsNextArrowRenderCaseStudies(true);
      }
      setIsPrevArrowRenderCaseStudies(false);
    }
  }, [swiperRefCaseStudies]);

  const handleRenderArrowsBlogs = useCallback(() => {
    if (swiperRefBlog && swiperRefBlog.activeIndex > 0) {
      if (swiperRefBlog.activeIndex >= 2 && swiperRefBlog.isEnd) {
        setIsNextArrowRenderBlog(false);
      } else {
        setIsNextArrowRenderBlog(true);
      }
      setIsPrevArrowRenderBlog(true);
    } else {
      if (swiperRefBlog.isEnd) {
        setIsNextArrowRenderBlog(false);
      } else {
        setIsNextArrowRenderBlog(true);
      }
      setIsPrevArrowRenderBlog(false);
    }
  }, [swiperRefBlog]);

  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
    handleRenderArrows();
  }, [handleRenderArrows, swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
    handleRenderArrows();
  }, [handleRenderArrows, swiperRef]);

  const handlePreviousTraining = useCallback(() => {
    swiperRefTraining?.slidePrev();
    handleRenderArrowsTrainings();
  }, [handleRenderArrowsTrainings, swiperRefTraining]);

  const handleNextTraining = useCallback(() => {
    swiperRefTraining?.slideNext();
    handleRenderArrowsTrainings();
  }, [handleRenderArrowsTrainings, swiperRefTraining]);

  const handlePreviousCaseStudies = useCallback(() => {
    swiperRefCaseStudies?.slidePrev();
    handleRenderArrowsCaseStudies();
  }, [handleRenderArrowsCaseStudies, swiperRefCaseStudies]);

  const handleNextCaseStudies = useCallback(() => {
    swiperRefCaseStudies?.slideNext();
    handleRenderArrowsCaseStudies();
  }, [handleRenderArrowsCaseStudies, swiperRefCaseStudies]);

  const handlePreviousBlog = useCallback(() => {
    swiperRefBlog?.slidePrev();
    handleRenderArrowsBlogs();
  }, [handleRenderArrowsBlogs, swiperRefBlog]);

  const handleNextBlog = useCallback(() => {
    swiperRefBlog?.slideNext();
    handleRenderArrowsBlogs();
  }, [handleRenderArrowsBlogs, swiperRefBlog]);

  const handleSearch = useCallback(() => {
    const searchTerm = searchInput.toLowerCase();

    setFilteredMaterials(
      materials.filter((material) => material.attributes.Title.toLowerCase().includes(searchTerm)),
    );

    setFilteredBlogs(
      blogs.filter((blog) => blog.attributes.Title.toLowerCase().includes(searchTerm)),
    );

    setFilteredTrainings(
      trainings.filter((training) => training.attributes.Title.toLowerCase().includes(searchTerm)),
    );

    setFilteredCaseStudies(
      caseStudies.filter((caseStudy) =>
        caseStudy.attributes.Title.toLowerCase().includes(searchTerm),
      ),
    );
  }, [searchInput, materials, blogs, trainings, caseStudies]);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (filterWrapperRef.current && !filterWrapperRef.current.contains(event.target as Node)) {
      setPathfulModuleFilterOpen(false);
      setTopicFilterOpen(false);
      setRoleFilterOpen(false);
      setTypeFilterOpen(false);
    }
  }, []);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    setIsNextArrowRender(filteredMaterials.length >= 3);
    setIsNextArrowRenderTraining(filteredTrainings.length >= 3);
    setIsNextArrowRenderCaseStudies(filteredCaseStudies.length >= 3);
    setIsNextArrowRenderBlog(filteredBlogs.length >= 3);
  }, [filteredMaterials, filteredTrainings, filteredCaseStudies, filteredBlogs]);

  return (
    <GlobalWrapper>
      <Container>
        <FiltersContainer>
          <FilterButton onClick={() => setRenderFilters(!renderFilters)}>Filters</FilterButton>
          <SearchContainer>
            <SearchInput
              id="searchInput"
              placeholder="Search"
              type="text"
              value={searchInput}
              onChange={(event) => setSearchInput(event.target.value)}
            />
            <SearchImage src={SearchIcon} alt="Search" />
          </SearchContainer>
          <FilterWrapper renderFilters={renderFilters} ref={filterWrapperRef}>
            {(pathfulModuleFilter.length > 0 ||
              topicsFilter.length > 0 ||
              rolesFilter.length > 0 ||
              typesFilter.length > 0) && (
              <TitleFilterContainer>
                <FilterTitle>Filters resources:</FilterTitle>
              </TitleFilterContainer>
            )}
            {pathfulModuleFilter.length > 0 && (
              <FilterContainer>
                <TitleFilterContainer
                  alignCenter
                  onClick={() => {
                    setPathfulModuleFilterOpen(!pathfulModuleFilterOpen);
                    setTopicFilterOpen(false);
                    setRoleFilterOpen(false);
                    setTypeFilterOpen(false);
                  }}
                >
                  <FilterTitle className="filter-title" boldText>
                    Pathful Module
                  </FilterTitle>
                  <IconContainer className="icon-container" isOpen={pathfulModuleFilterOpen}>
                    <ArrowIcon />
                  </IconContainer>
                </TitleFilterContainer>
                <FilterItemsList>
                  {pathfulModuleFilterOpen &&
                    pathfulModuleFilter.map((module, index) => (
                      <FilterInput
                        key={module.attributes.Pathful_Module}
                        lastChild={pathfulModuleFilter.length - 1 === index}
                      >
                        <CheckboxInput
                          name={module.attributes.Pathful_Module}
                          value={module.attributes.Pathful_Module}
                          checked={filterApplied.includes(module.attributes.Pathful_Module)}
                          onChange={() => handelFilterApplied(module.attributes.Pathful_Module)}
                          type="checkbox"
                        />
                        {module.attributes.Pathful_Module}
                      </FilterInput>
                    ))}
                </FilterItemsList>
              </FilterContainer>
            )}
            {topicsFilter.length > 0 && (
              <FilterContainer>
                <TitleFilterContainer
                  alignCenter
                  onClick={() => {
                    setTopicFilterOpen(!topicFilterOpen);
                    setRoleFilterOpen(false);
                    setPathfulModuleFilterOpen(false);
                    setTypeFilterOpen(false);
                  }}
                >
                  <FilterTitle className="filter-title" boldText>
                    Topics
                  </FilterTitle>
                  <IconContainer className="icon-container" isOpen={topicFilterOpen}>
                    <ArrowIcon />
                  </IconContainer>
                </TitleFilterContainer>
                <FilterItemsList>
                  {topicFilterOpen &&
                    topicsFilter.map((topic, index) => (
                      <FilterInput
                        key={topic.attributes.Topic}
                        lastChild={topicsFilter.length - 1 === index}
                      >
                        <CheckboxInput
                          name={topic.attributes.Topic}
                          value={topic.attributes.Topic}
                          checked={filterApplied.includes(topic.attributes.Topic)}
                          onChange={() => handelFilterApplied(topic.attributes.Topic)}
                          type="checkbox"
                        />
                        {topic.attributes.Topic}
                      </FilterInput>
                    ))}
                </FilterItemsList>
              </FilterContainer>
            )}
            {rolesFilter.length > 0 && (
              <FilterContainer>
                <TitleFilterContainer
                  alignCenter
                  onClick={() => {
                    setRoleFilterOpen(!roleFilterOpen);
                    setPathfulModuleFilterOpen(false);
                    setTopicFilterOpen(false);
                    setTypeFilterOpen(false);
                  }}
                >
                  <FilterTitle className="filter-title" boldText>
                    Role
                  </FilterTitle>
                  <IconContainer className="icon-container" isOpen={roleFilterOpen}>
                    <ArrowIcon />
                  </IconContainer>
                </TitleFilterContainer>
                <FilterItemsList>
                  {roleFilterOpen &&
                    rolesFilter.map((role, index) => (
                      <FilterInput
                        key={role.attributes.Role}
                        lastChild={rolesFilter.length - 1 === index}
                      >
                        <CheckboxInput
                          name={role.attributes.Role}
                          value={role.attributes.Role}
                          checked={filterApplied.includes(role.attributes.Role)}
                          onChange={() => handelFilterApplied(role.attributes.Role)}
                          type="checkbox"
                        />
                        {role.attributes.Role}
                      </FilterInput>
                    ))}
                </FilterItemsList>
              </FilterContainer>
            )}
            {typesFilter.length > 0 && (
              <FilterContainer>
                <TitleFilterContainer
                  alignCenter
                  onClick={() => {
                    setTypeFilterOpen(!typeFilterOpen);
                    setRoleFilterOpen(false);
                    setPathfulModuleFilterOpen(false);
                    setTopicFilterOpen(false);
                  }}
                >
                  <FilterTitle className="filter-title" boldText>
                    Type
                  </FilterTitle>
                  <IconContainer className="icon-container" isOpen={typeFilterOpen}>
                    <ArrowIcon />
                  </IconContainer>
                </TitleFilterContainer>
                <FilterItemsList>
                  {typeFilterOpen &&
                    typesFilter.map((type, index) => (
                      <FilterInput
                        key={type.attributes.Type}
                        lastChild={typesFilter.length - 1 === index}
                      >
                        <CheckboxInput
                          name={type.attributes.Type}
                          value={type.attributes.Type}
                          checked={filterApplied.includes(type.attributes.Type)}
                          onChange={() => handelFilterApplied(type.attributes.Type)}
                          type="checkbox"
                        />
                        {type.attributes.Type}
                      </FilterInput>
                    ))}
                </FilterItemsList>
              </FilterContainer>
            )}
          </FilterWrapper>
        </FiltersContainer>
        {filteredMaterials.length > 0 && (
          <>
            <ArticleSection>
              <ArticleTitle>Resources</ArticleTitle>
              <ArticleViewMore href={PATHS.MATERIAL}>View more</ArticleViewMore>
            </ArticleSection>
            <ArticlesContainer>
              {isNextArrowRender && (
                <ArrowNavigationNext
                  className=".swiper-button-next"
                  onClick={handleNext}
                  render={isNextArrowRender}
                >
                  <CarrouselIcon />
                </ArrowNavigationNext>
              )}
              {isPrevArrowRender && (
                <ArrowNavigationPrev
                  render={isPrevArrowRender}
                  className=".swiper-button-prev"
                  onClick={handlePrevious}
                >
                  <CarrouselIcon />
                </ArrowNavigationPrev>
              )}
              <CarrouselContainer>
                <Carrousel
                  slidesPerView={1.1}
                  spaceBetween={26}
                  navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                  }}
                  onSwiper={setSwiperRef}
                  className="mySwiper"
                  watchOverflow
                  breakpoints={{
                    1280: { slidesPerView: 2.8 },
                    1024: { slidesPerView: 3 },
                    769: { slidesPerView: 2.1 },
                  }}
                >
                  {filteredMaterials.map((material) => (
                    <SwiperSlide key={material.attributes.Title} className="swiper-slide">
                      <MaterialCard material={material} />
                    </SwiperSlide>
                  ))}
                </Carrousel>
              </CarrouselContainer>
            </ArticlesContainer>
          </>
        )}
        {filteredBlogs.length > 0 && (
          <>
            <ArticleSection style={{ marginTop: '25px' }}>
              <ArticleTitle>Blog</ArticleTitle>
              <ArticleViewMore href={PATHS.BLOG}>View more</ArticleViewMore>
            </ArticleSection>
            <ArticlesContainer>
              {isNextArrowRenderBlog && (
                <ArrowNavigationNext
                  className=".swiper-button-next-blog"
                  onClick={handleNextBlog}
                  render={isNextArrowRenderBlog}
                >
                  <CarrouselIcon />
                </ArrowNavigationNext>
              )}
              {isPrevArrowRenderBlog && (
                <ArrowNavigationPrev
                  render={isPrevArrowRenderBlog}
                  className=".swiper-button-prev-blog"
                  onClick={handlePreviousBlog}
                >
                  <CarrouselIcon />
                </ArrowNavigationPrev>
              )}
              <CarrouselContainer>
                <Carrousel
                  slidesPerView={1.1}
                  spaceBetween={26}
                  className="mySwiper"
                  navigation={{
                    nextEl: '.swiper-button-next-blog',
                    prevEl: '.swiper-button-prev-blog',
                  }}
                  onSwiper={setSwiperRefBlog}
                  breakpoints={{
                    1280: { slidesPerView: 2.8 },
                    1024: { slidesPerView: 3 },
                    769: { slidesPerView: 2.1 },
                  }}
                >
                  {handleBlogsCarrousel().map((blog) => (
                    <SwiperSlide key={blog.attributes.Title} className="swiper-slide">
                      <BlogCard blog={blog} />
                    </SwiperSlide>
                  ))}
                </Carrousel>
              </CarrouselContainer>
            </ArticlesContainer>
          </>
        )}
        {filteredTrainings.length > 0 && (
          <>
            <ArticleSection>
              <ArticleTitle>Training</ArticleTitle>
              <ArticleViewMore href={PATHS.TRAINING}>View more</ArticleViewMore>
            </ArticleSection>
            <ArticlesContainer>
              {isNextArrowRenderTraining && (
                <ArrowNavigationNext
                  className=".swiper-button-next-training"
                  onClick={handleNextTraining}
                  render={isNextArrowRenderTraining}
                >
                  <CarrouselIcon />
                </ArrowNavigationNext>
              )}
              {isPrevArrowRenderTraining && (
                <ArrowNavigationPrev
                  render={isPrevArrowRenderTraining}
                  className=".swiper-button-prev-training"
                  onClick={handlePreviousTraining}
                >
                  <CarrouselIcon />
                </ArrowNavigationPrev>
              )}
              <CarrouselContainer>
                <Carrousel
                  slidesPerView={1.1}
                  spaceBetween={26}
                  className="mySwiper"
                  navigation={{
                    nextEl: '.swiper-button-next-training',
                    prevEl: '.swiper-button-prev-training',
                  }}
                  onSwiper={setSwiperRefTraining}
                  breakpoints={{
                    1280: { slidesPerView: 2.8 },
                    1024: { slidesPerView: 3 },
                    769: { slidesPerView: 2.1 },
                  }}
                >
                  {handleTrainingsCarrousel().map((training) => (
                    <SwiperSlide key={training.attributes.Title} className="swiper-slide">
                      <TrainingCard training={training} />
                    </SwiperSlide>
                  ))}
                </Carrousel>
              </CarrouselContainer>
            </ArticlesContainer>
          </>
        )}
        {filteredCaseStudies.length > 0 && (
          <>
            <ArticleSection>
              <ArticleTitle>Case Studies</ArticleTitle>
              <ArticleViewMore href={PATHS.CASESTUDIES}>View more</ArticleViewMore>
            </ArticleSection>
            <ArticlesContainer>
              {isNextArrowRenderCaseStudies && (
                <ArrowNavigationNext
                  className=".swiper-button-next-case-studies"
                  onClick={handleNextCaseStudies}
                  render={isNextArrowRenderCaseStudies}
                >
                  <CarrouselIcon />
                </ArrowNavigationNext>
              )}
              {isPrevArrowRenderCaseStudies && (
                <ArrowNavigationPrev
                  render={isPrevArrowRenderCaseStudies}
                  className=".swiper-button-prev-case-studies"
                  onClick={handlePreviousCaseStudies}
                >
                  <CarrouselIcon />
                </ArrowNavigationPrev>
              )}
              <CarrouselContainer>
                <Carrousel
                  slidesPerView={1.1}
                  spaceBetween={26}
                  navigation={{
                    nextEl: '.swiper-button-next-case-studies',
                    prevEl: '.swiper-button-prev-case-studies',
                  }}
                  onSwiper={setSwiperRefCaseStudies}
                  className="mySwiper"
                  watchOverflow
                  breakpoints={{
                    1280: { slidesPerView: 2.8 },
                    1024: { slidesPerView: 3 },
                    769: { slidesPerView: 2.1 },
                  }}
                >
                  {filteredCaseStudies.map((caseStudie) => (
                    <SwiperSlide key={caseStudie.attributes.Title} className="swiper-slide">
                      <CaseStudiesCard caseStudie={caseStudie} />
                    </SwiperSlide>
                  ))}
                </Carrousel>
              </CarrouselContainer>
            </ArticlesContainer>
          </>
        )}
      </Container>
    </GlobalWrapper>
  );
};
export default ArticleList;
