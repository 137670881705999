import { DESKTOP_1200, TABLET_768 } from '@global/styles/sizes';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 227px;
  padding: 20px 26px 0;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fafafa;

  @media (max-width: ${DESKTOP_1200}px) {
    width: auto;
    margin-top: 14px;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 36px;
  }
  @media (max-width: ${TABLET_768}px) {
    flex-direction: column;
  }
`;

export const BoxContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #d5d5d5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: ${DESKTOP_1200}px) {
    width: 26%;
    border-bottom: none;
  }

  @media (max-width: ${TABLET_768}px) {
    width: 100%;
    border-bottom: 1px solid #d5d5d5;
  }
`;

export const TitleContainer = styled.div`
  margin-bottom: 12px;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  color: #000000;

  @media (max-width: ${DESKTOP_1200}px) {
    width: 100%;
  }
`;

export const AuthorData = styled.div`
  margin: 15px 0 4px;
  display: inline;
  font-family: 'Poppins';
  font-size: 14px;
  line-height: 21px;
  color: #000000;

  @media (max-width: ${DESKTOP_1200}px) {
    line-height: 18px;
    margin-bottom: 6px;
  }
`;

export const ArticleTitle = styled.a`
  margin-bottom: 6px;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #0e8cb9;
  }
`;

export const ArticleLink = styled.a`
  margin-bottom: 22px;
  text-decoration: none;
  font-family: 'Poppins';
  font-size: 14px;
  line-height: 21px;
  color: #00a8cf;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: ${DESKTOP_1200}px) {
    margin-bottom: 26px;
  }
`;

export const Slash = styled.p`
  display: inline;
  @media (max-width: ${DESKTOP_1200}px) {
    display: none;
  }
  @media (max-width: ${TABLET_768}px) {
    display: inline;
  }
`;

export const WhiteSpace = styled.p`
  display: none;
  @media (max-width: ${DESKTOP_1200}px) {
    display: block;
  }
  @media (max-width: ${TABLET_768}px) {
    display: none;
  }
`;
