import { COLORS } from '@global/styles/colors';
import { DESKTOP_1200, DESKTOP_1280, TABLET_768, TABLET_992 } from '@global/styles/sizes';
import styled, { css } from 'styled-components';

export const BoxContainer = styled.div<{ isMainAccess: boolean }>`
  width: 172px;
  box-sizing: border-box;
  padding: 20px 25px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  background-color: #f2f7fb;
  @media (max-width: ${DESKTOP_1280}px) {
    width: 31%;
    padding: 16px;
    justify-content: flex-end;
    flex-direction: column-reverse;
    ${({ isMainAccess }) =>
      isMainAccess &&
      css`
        width: 30% !important;
      `}
  }
  @media (max-width: ${TABLET_768}px) {
    width: 100%;
    flex-direction: row;
    ${({ isMainAccess }) =>
      isMainAccess &&
      css`
        width: 100% !important;
      `}
  }
`;

export const TitleContainer = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  color: ${COLORS.resolutionBlue};

  @media (max-width: ${DESKTOP_1200}px) {
    font-size: 17px;
    line-height: 24px;
  }
  @media (max-width: ${TABLET_992}px) {
    min-height: 48px;
  }
  @media (max-width: ${TABLET_768}px) {
    min-height: 0;
    margin: 10px 0;
  }
`;

export const SubtitleContainer = styled.div`
  font-family: 'Poppins';
  font-size: 16px;
  line-height: 22px;
  color: ${COLORS.resolutionBlue};

  @media (max-width: ${DESKTOP_1280}px) {
    min-height: 44px;
  }
`;

export const Button = styled.a`
  font-family: 'Poppins';
  font-size: 16px;
  line-height: 25px;
  color: #00a8cf;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const TextContainer = styled.div`
  width: max-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media (max-width: ${DESKTOP_1280}px) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: right;
  align-items: end;

  @media (max-width: ${DESKTOP_1280}px) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 26px;
  }

  @media (max-width: ${TABLET_768}px) {
    margin-top: 13px;
    flex-direction: column;
    gap: 16px;
  }
`;

export const ImageContainer = styled.div`
  width: 36px;
  height: 36px;

  img {
    width: 36px !important;
    height: 36px;
  }

  @media (max-width: ${DESKTOP_1280}px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  @media (max-width: ${TABLET_768}px) {
    width: auto;
  }
`;
