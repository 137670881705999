import React from 'react';
import { GlobalWrapper } from '@global/styles/grid';
import { CMSParseImage } from '@components/StrapiComponents/ImageParser';
import { SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { transformToSlug } from 'helpers/slugHelper';
import { ArticleType } from '@sections/ResourcesCenterHero/ResourcesCenterHero.types';
import { PATHS } from '@global/constants/urls';
import {
  PostContainer,
  Carrousel,
  DataContainer,
  ImageContainer,
  MoreInfoContainer,
  MoreInfoText,
  SubtitleContainer,
  Tag,
  Title,
  TitleContainer,
} from './RelatedPost.styles';
import { RelatedPostProps } from './RelatedPost.types';

const RelatedPost = ({ relatedPost, articleType }: RelatedPostProps) => {
  SwiperCore.use([Navigation]);
  const handleArticleType = () => {
    switch (articleType) {
      case ArticleType.Training:
        return {
          href: PATHS.TRAINING,
          tagText: 'Training',
        };

      case ArticleType.WebinarPost:
        return {
          href: PATHS.WEBINAR,
          tagText: 'Webinar',
        };

      default:
        return {
          href: PATHS.MATERIAL,
          tagText: 'Material',
        };
    }
  };

  if (relatedPost.length > 0) {
    return (
      <GlobalWrapper>
        <Title>Related Content</Title>
        <Carrousel
          slidesPerView={1}
          spaceBetween={33}
          breakpoints={{
            1280: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 'auto',
            },
          }}
        >
          {relatedPost.map((post) => {
            const postData = handleArticleType();
            return (
              <SwiperSlide key={post.attributes.Title} className="swiper-slide">
                <PostContainer
                  href={`${postData.href}/${transformToSlug(post.attributes.Title.toLowerCase())}`}
                >
                  <ImageContainer>
                    <Tag articleType={articleType}>{postData.tagText}</Tag>
                    {CMSParseImage(post.attributes.Small_Image.data)}
                  </ImageContainer>
                  <DataContainer className="article-data">
                    <TitleContainer>{post.attributes.Title}</TitleContainer>
                    <SubtitleContainer>{post.attributes.Subtitle}</SubtitleContainer>
                    {articleType === ArticleType.Material && (
                      <MoreInfoContainer>
                        <MoreInfoText>{post.attributes.Author}</MoreInfoText>
                        <MoreInfoText>{post.attributes.Time_To_Read}</MoreInfoText>
                      </MoreInfoContainer>
                    )}
                  </DataContainer>
                </PostContainer>
              </SwiperSlide>
            );
          })}
        </Carrousel>
      </GlobalWrapper>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

export default RelatedPost;
