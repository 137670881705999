import { DESKTOP_1200, DESKTOP_1280, TABLET_600, TABLET_768 } from '@global/styles/sizes';
import styled, { css } from 'styled-components';

export const CollectionContainer = styled.div`
  margin-top: 39px;
  display: flex;
  flex-direction: row;
  gap: 26px;

  @media (max-width: ${DESKTOP_1200}px) {
    flex-direction: column;
    margin: 39px 0 0;
  }
`;
export const RelatedPostContainer = styled.div`
  padding-bottom: 90px;
  background-color: #fafafa;

  @media (max-width: ${DESKTOP_1200}px) {
    margin-top: 60px;
    padding-bottom: 40px;
  }

  @media (max-width: ${TABLET_768}px) {
    padding-bottom: 60px;
  }
`;

export const CollectionBodyContainer = styled.div`
  width: 74%;

  @media (max-width: ${DESKTOP_1200}px) {
    width: 100%;
  }
`;

export const QuickAccessContainer = styled.div<{
  isResourceCenter?: boolean;
  isMainAccess?: boolean;
}>`
  width: ${({ isResourceCenter }) => (isResourceCenter ? '291px' : '172px')};
  display: flex;
  flex-direction: column;
  gap: 34px;

  @media (max-width: ${DESKTOP_1280}px) {
    ${({ isResourceCenter, isMainAccess }) =>
      (isResourceCenter || isMainAccess) &&
      css`
        width: 100%;
      `};
  }
  @media (max-width: ${DESKTOP_1200}px) {
    width: 100%;
  }
`;

export const InActionContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 27px;

  @media (max-width: ${DESKTOP_1280}px) {
    flex-direction: column-reverse;
    margin-left: 0;
  }
  @media (max-width: ${TABLET_600}px) {
    margin: 0 0 40px;
  }
`;

export const InActionIndexContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-left: 50px;
  display: flex;
  flex-direction: row;
  gap: 27px;

  @media (max-width: ${DESKTOP_1280}px) {
    flex-direction: column-reverse;
    margin-left: 0;
  }
  @media (max-width: ${TABLET_600}px) {
    margin: 50px 0 40px;
  }
`;

export const CollectionIndexContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 35px;
  padding-bottom: 60px;

  @media (max-width: ${DESKTOP_1200}px) {
    padding-bottom: 42px;
  }
  @media (max-width: ${TABLET_768}px) {
    padding-bottom: 40px;
  }
`;

export const CollectionBackHomepage = styled.a`
  margin-bottom: 10px;
  display: flex;
  font-family: 'Poppins';
  text-decoration: none;
  line-height: 25px;
  font-size: 16px;
  color: #00a8cf;

  &:hover {
    text-decoration: underline;
  }
`;
