import React from 'react';
import { graphql } from 'gatsby';

// components
import Head from '@components/Head';

import { IStrapiProps } from '@components/StrapiParser/types';
import BodyParser from '@components/StrapiParser';
import { LayoutContainer } from '@components/Layout';
import { WelcomePromoBar } from '@global/data/promobarsData';
import QuickAccessPanel from '@sections/QuickAccessPanel/QuickAccessPanel';
import MostPopular from '@sections/MostPopular/MostPopular';
import { GlobalWrapper } from '@global/styles/grid';
import TrainingTitle from '@sections/TrainingTitle/TrainingTitile';
import RelatedPost from '@sections/RelatedPost/RelatedPost';
import { PATHS } from '@global/constants/urls';
import { ArticleType } from '@sections/ResourcesCenterHero/ResourcesCenterHero.types';
import {
  CollectionBodyContainer,
  CollectionContainer,
  QuickAccessContainer,
  RelatedPostContainer,
  CollectionBackHomepage,
} from './styles';

const Training = ({ data }: any) => {
  const attributes = data?.cms?.webinar?.data?.attributes;
  const quickAccessPanelData = data?.cms?.inActionQuickPanelAccesses?.data;
  const mostPopularTrainings = data?.cms?.webinars?.data;
  return (
    <main>
      <Head
        title="Your College & Career Readiness Journey | Pathful"
        description="Pathful is the complete college and career readiness system that helps students decide what they want to be and how to prepare for life beyond the classroom."
        tag="classroom, career, internship, learning, curriculum, career exploration, discovery, planning, path, work-based learning, career readiness, education outreach, real-world learning, course planner, graduation plans, life skills, digital lessons, virtual lessons, virtual job shadow, job shadow, job shadowing, career videos, career technical education, career placement, K12 internship opportunities"
        url="https://pathful.com/"
        imageUrl="https://pathful.com/pathful_preview.png"
      />
      <LayoutContainer
        promobarConfig={WelcomePromoBar}
        activeLogo={attributes?.Layout?.Topbar_Scroll_Logo}
        topbarBorderColor={attributes?.Layout?.Topbar_Details_Color}
        topbarMenuId={attributes?.Layout?.Topbar_Menu}
        footerMenuId={attributes?.Layout?.Footer_Menu}
        floatingTopbar
      >
        <GlobalWrapper>
          <CollectionContainer>
            <CollectionBodyContainer>
              <CollectionBackHomepage href={PATHS.TRAINING}>{`<`} Back</CollectionBackHomepage>
              <TrainingTitle
                title={attributes.Title}
                subtitle={attributes.Subtitle}
                schedule={attributes.Schedule}
                duration={attributes.Duration}
                types={attributes.Type_Filters}
                careers={attributes.Pathful_Modules}
                roles={attributes.Roles}
                topics={attributes.Topics}
                trainingLink={attributes.Redirect_Webinar_Link}
              />
              {attributes?.Body &&
                attributes?.Body.map(
                  (strapiComponent: IStrapiProps['component'], index: number) => (
                    <BodyParser
                      key={strapiComponent.id}
                      component={{ ...strapiComponent, articleType: ArticleType.Training }}
                      index={index}
                    />
                  ),
                )}
            </CollectionBodyContainer>
            <QuickAccessContainer>
              <QuickAccessPanel data={quickAccessPanelData} />
              {mostPopularTrainings?.length > 0 && (
                <MostPopular
                  mostPopular={mostPopularTrainings}
                  articleType={ArticleType.Training}
                />
              )}
            </QuickAccessContainer>
          </CollectionContainer>
        </GlobalWrapper>
        <RelatedPostContainer>
          <GlobalWrapper>
            <RelatedPost
              articleType={ArticleType.Training}
              relatedPost={data.cms?.relatedPost.data}
            />
          </GlobalWrapper>
        </RelatedPostContainer>
      </LayoutContainer>
    </main>
  );
};

export const pageQuery = graphql`
  query ($id: ID!, $topics: [String]!, $roles: [String]!, $careers: [String]!, $types: [String]!) {
    cms {
      inActionQuickPanelAccesses(pagination: { limit: -1 }) {
        data {
          attributes {
            Quick_Panel_Access_CTA_Link
            Quick_Panel_Access_CTA_Text
            Quick_Panel_Access_Subtitle
            Quick_Panel_Access_Title
            Quick_Panel_Access_Icon {
              data {
                attributes {
                  alternativeText
                  url
                  mime
                  urlJSON
                }
              }
            }
          }
        }
      }
    }
    cms {
      webinar(id: $id) {
        data {
          attributes {
            Layout {
              Topbar_Scroll_Logo {
                data {
                  attributes {
                    alternativeText
                    url
                    mime
                    urlJSON
                  }
                }
              }
              Topbar_Details_Color
              Footer_Menu
              Topbar_Menu
            }
            slug
            Title
            Subtitle
            Most_Popular
            Duration
            Schedule
            Redirect_Webinar_Link
            Body {
              ...Paragraph
              ...MultipleAuthored
              ...TrainingCTA
              ...Authored
              ...BlockQuote
              ...Image
              ...TextWithImage
              ...CompactQuote
            }
            Roles {
              data {
                attributes {
                  Role
                }
              }
            }
            Topics {
              data {
                attributes {
                  Topic
                }
              }
            }
            Pathful_Modules {
              data {
                attributes {
                  Pathful_Module
                }
              }
            }
            Type_Filters {
              data {
                attributes {
                  Type
                }
              }
            }
          }
        }
      }
    }
    cms {
      webinars(filters: { Most_Popular: { eq: true } }) {
        data {
          attributes {
            slug
            Title
            Schedule
            Speaker
          }
        }
      }
      relatedPost: webinars(
        filters: {
          or: [
            { Topics: { Topic: { in: $topics } } }
            { Roles: { Role: { in: $roles } } }
            { Type_Filters: { Type: { in: $types } } }
            { Pathful_Modules: { Pathful_Module: { in: $careers } } }
          ]
        }
        pagination: { limit: 3 }
      ) {
        data {
          attributes {
            slug
            Title
            Subtitle
            Small_Image {
              data {
                attributes {
                  alternativeText
                  url
                  mime
                  urlJSON
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Training;
